import React, { useState } from "react";
import styles from "./styles.module.scss";
import { FaChevronDown } from "react-icons/fa";

function Select({
  label,
  width = "90%",
  appearance = "default",
  value,
  data,
  onclick,
}: any) {
  const [opend, setOpened] = useState(false);

  return (
    <div
      className={`
        ${styles.container} 
        ${appearance === "default" && styles.default}
        ${appearance === "primary" && styles.primary}
        ${appearance === "complementary" && styles.complementary}
        ${appearance === "ghost" && styles.ghost}
      `}
      style={{ width: width && width }}
      onMouseLeave={() => setOpened(false)}
    >
      <div
        className={styles.field}
        onClick={() => {
          setOpened(true);
        }}
      >
        <p className={` ${styles.label} ${value ? styles.labelValueOn : ""}`}>
          {label}
        </p>
        <p>{value}</p>
        <div className={styles.icon}>
          <FaChevronDown />
        </div>
      </div>

      {opend && (
        <div className={styles.options}>
          {data.map((option) => (
            <div
              className={styles.option}
              onClick={() => {
                setOpened(false);
                onclick(option.title);
              }}
            >
              {option.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Select;
