import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import useSWR from "swr";
import { useNavigate, useParams } from "react-router-dom";
import { maskCep, maskMoney, maskReset } from "../../utils/mask";
import { DataContext } from "../../context/DataContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  Button,
  Column,
  Input,
  Row,
  Text,
  Spacing,
  SearchField,
  Modal,
  Footer,
} from "../../components";

import logo from "../../assets/logos/portal-de-saude-logo.png";
import errorIcon from "../../assets/icons/error-unenabled-location-icon.png";
import check from "../../assets/icons/icon-check-green.png";
import arrow from "../../assets/icons/icon-arrow-right-white.png";

const fetcher = (url) => axios.get(url).then((res) => res.data);

function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, error } = useSWR(
    `${process.env.REACT_APP_LINK_API}/packages/${id}`,
    fetcher
  );
  const [packages, setPackages] = useState([]);
  const [city, setCity] = useState("Porto Alegre");
  const [modalLocation, setModalLocation] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [cep, setCep] = useState("");
  const { context, updateLocation, updatePackages }: any =
    useContext(DataContext);
  const storage = JSON.parse(localStorage.getItem("@data"));

  useEffect(() => {
    if (storage && storage.location.postCode !== "") {
      setCep(storage.location.postCode);
      searchUserLocation(storage.location.postCode);
      updateProducts(context.packages);
    } else {
      setModalLocation(true);
    }
  }, []);

  function searchUserLocation(data: any) {
    axios
      .get(
        `${
          process.env.REACT_APP_LINK_API
        }/packages-by-regions-and-category/${maskReset(data)}/${id}`
      )
      .then((response) => {
        if (response.data.filteredPackages.length === 0) {
          setModalErrorIsOpen(true);
        } else {
          getUserCity(data);
          setCep(data);
          updatePackages(response.data.filteredPackages);
          setPackages(response.data.filteredPackages);
        }
      })
      .finally(() => {
        setModalLocation(false);
        setCep("");
      });
  }

  function getUserCity(data: any) {
    axios
      .get(`https://viacep.com.br/ws/${maskReset(data)}/json/`)
      .then((response) => {
        setCity(response.data.localidade);
        updateLocation({
          postCode: maskReset(response.data.cep),
          city: response.data.localidade,
        });
      });
  }

  function updateProducts(data: any) {
    const avaibleProducts = [];
    data.map((product) => {
      if (product.package_categories_id === Number(id)) {
        avaibleProducts.push(product);
      }
    });
    setPackages([...avaibleProducts]);
  }

  return (
    data && (
      <div className={styles.container}>
        <Modal
          isOpen={modalLocation}
          title="Informe o CEP da sua região"
          description="Iremos filtrar os serviços de saúde associados a
        Conexxa disponíveis na sua região."
          footerButtonFirst="Cancelar"
          footerButtonFirstAppearance="ghost"
          footerButtonFirstWidth="74px"
          onClickFooterFirst={() => setModalLocation(false)}
          footerButtonSecond="Continuar"
          footerButtonSecondAppearance="primary"
          footerButtonSecondWidth="74px"
          onClickFooterSecond={() => searchUserLocation(cep)}
          onClickClose={() => setModalLocation(false)}
        >
          <Spacing size="large" />
          <Input
            name="cep"
            type="text"
            width="100%"
            maxLength="10"
            placeholder="CEP"
            value={cep}
            onChange={(event) => {
              setCep(maskCep(event.target.value));
            }}
          />
          <Spacing size="large" />
        </Modal>

        <Modal
          isOpen={modalErrorIsOpen}
          image={errorIcon}
          imageWidth="70px"
          title="Serviço indisponível em sua região"
          description="Este pacote não está disponível para sua região. Mude a sua localização ou selecione um dos serviços disponíveis da sua região"
          onClickClose={() => setModalErrorIsOpen(false)}
        >
          <Spacing size="xxx-large" />
          <Row>
            <Column position="centered">
              <Button
                text="Ver serviços disponíveis"
                width="195px"
                onClick={() => setModalErrorIsOpen(false)}
              />
              <Spacing size="medium" />
              <Button
                text="Mudar localização"
                appearance="ghost"
                width="165px"
                onClick={() => {
                  setModalErrorIsOpen(false);
                  setModalLocation(true);
                }}
                iconPosition="left"
              >
                <FaMapMarkerAlt />
              </Button>
            </Column>
          </Row>
        </Modal>

        <div className={styles.navbar}>
          <img
            src={logo}
            alt="logo conexx saúde"
            draggable="false"
            onClick={() => navigate("/")}
          />
        </div>
        <SearchField
          city={city}
          onClick={(event) => searchUserLocation(event)}
        />
        <div className={styles.banner}>
          <img
            src={`https://conexx.s3.amazonaws.com/${data.categories.image}`}
            className={styles.bannerBackground}
            draggable="false"
          />
        </div>
        <div className={styles.about}>
          <div className={styles.aboutContent}>
            <h1>{data.categories.name}</h1>
            <Spacing size="xx-small" />
            <p>{data.categories.description}</p>
          </div>
        </div>
        <div className={styles.packages}>
          {packages.length > 0 ? (
            <div className={styles.packagesRow}>
              {packages.map((elem) => (
                <div className={styles.package}>
                  <div className={styles.packageContent}>
                    <Spacing size="x-large" />
                    <h1>{elem.name}</h1>
                    <Spacing size="large" />
                    <div className={styles.value}>
                      <h1>R$ {maskMoney(Number(elem.value))}</h1>
                      <p>ou 2x no cartão sem juros</p>
                    </div>
                    <Row position="centered">
                      <Button
                        text="Começar"
                        width="130px"
                        onClick={() => navigate(`/detalhe/${elem.id}`)}
                      >
                        <img src={arrow} alt="" />
                      </Button>
                    </Row>
                    <div className={styles.attributes}>
                      {elem.exams.map((exam) => (
                        <div className={styles.attributeItem}>
                          <img src={check} alt="" />
                          <p>{exam.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <Text appearance="grey">
                Nenhum pacote foi encontrado para a sua região
              </Text>
            </div>
          )}
        </div>
        <Footer />
      </div>
    )
  );
}

export default Product;
