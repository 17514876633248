import React from "react";
import styles from "./styles.module.scss";
import success from "../../assets/icons/success-icon.png";
import error from "../../assets/icons/excplamation-icon.png";

function Alerts({ type, title, text }) {
  return (
    <div
      className={`
      ${styles.alertComponent} 
      ${type === "success" ? styles.success : ""} 
      ${type === "error" ? styles.error : ""}
      `}
    >
      <img src={type === "success" ? success : error} alt="" />
      <div className={styles.column}>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Alerts;
