export function maskReset(value: string) {
  // eslint-disable-next-line no-param-reassign
  value = value.toString().replace(/( )+/g, "");                                    // Converte para string e permite apenas dígitos
  // eslint-disable-next-line no-useless-escape
  return value.replace(/(\.|\/|\-|\,|\(|\))/g, "");                                 // Remove pontos, virgulas, traços e barras
}

export function maskMoney(value: any) {
  value.toString();
  if (!value) return "0,00";                                                        // Acrescenta uma virgula para separar as dezenas

  if (isNaN(value)) {
    return "0,00";                                                                  // qualquer outro valor diferente do esperado é retornado o valor zerado
  }

  return value
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function maskCep(value: string) {
  return value.replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, "$1.$2-$3");            // Ajusta a string colocando os pontos e o traço entre os caracteres
}

export function maskDate(value: string) {
  return value.replace(/^([\d]{2})\/*([\d]{2})/, "$1/$2");                          // Coloca barra após 2 caracteres
}

export function maskBirthDate(value: string) {
  return value.replace(/^([\d]{2})\/*([\d]{2})\/*([\d]{4})/, "$1/$2/$3");           // Coloca barra após 2 caracteres
}

export function maskCPF(value: string) {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");              // Ajusta a string colocando os pontos e o traço entre os caracteres
}

export function maskPhone(value: string) {
  let phone = value.replace(/\D/g, "");                                             // Permite apenas dígitos
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");                                // Coloca parenteses envolta dos primeiros dois caracteres
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");                                   // Coloca um traço após 4 caracteres
  return phone;
}

export function maskCreditCard(v: string) {
  v = v.replace(/\D/g, "");                                                         // Permite apenas dígitos
  v = v.replace(/(\d{4})/g, "$1 ");                                                 // Coloca um ponto a cada 4 caracteres
  v = v.replace(/\.$/, "");                                                         // Remove o ponto se estiver sobrando
  v = v.substring(0, 19);                                                           // Limita o tamanho

  return v;
}
