import React from "react";
import styles from "./styles.module.scss";
import { Description, Spacing } from "..";
import * as Scroll from "react-scroll";
import dayjs from "dayjs";

import logo from "../../assets/logos/portal-de-saude-logo.png";
import iconEmail from "../../assets/footer/icon-email-white-footer.png";
import iconPhone from "../../assets/footer/icon-phone-white-footer.png";
import linkedin from "../../assets/footer/icon-linkedin-white-footer.png";
import youtube from "../../assets/footer/icon-youtube-white-footer.png";
import instagram from "../../assets/footer/icon-instagram-white-footer.png";
import facebook from "../../assets/footer/icon-facebook-white-footer.png";

function Footer() {
  const ScrollLink = Scroll.Link;

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={`${styles.footerColumn} ${styles.start}`}>
          <img src={logo} className={styles.logo} draggable="false" />
          <Spacing size="small" />
          <Description appearance="white" textAlign="start">
            Oferecemos soluções <br />
            eficientes e econômicas para <br />
            cuidar da sua saúde com um <br />
            novo olhar.
          </Description>
          <Spacing size="small" />
          <div className={styles.footerSocial}>
            <img src={linkedin} alt="" />
            <img src={youtube} alt="" />
            <img src={instagram} alt="" />
            <img src={facebook} alt="" />
          </div>
        </div>
        <div className={`${styles.footerColumn} ${styles.centered}`}>
          <div>
            <ScrollLink
              to="packages-section"
              spy={true}
              smooth={true}
              duration={500}
            >
              <p>Planos e Serviços</p>
            </ScrollLink>
            <Spacing size="large" />
            <ScrollLink
              to="services-section"
              spy={true}
              smooth={true}
              duration={500}
            >
              <p>Procedimentos</p>
            </ScrollLink>
          </div>
        </div>
        <div className={`${styles.footerColumn} ${styles.centered}`}>
          <div>
            <p>
              <img src={iconEmail} alt="" /> contato@email.com.br
            </p>
            <Spacing size="large" />
            <p>
              <img src={iconPhone} alt="" /> +55 (11) 998437182
            </p>
          </div>
        </div>
      </div>
      <Spacing size="xxx-large" />
      <div className={styles.footerContent}>
        <div className={`${styles.footerColumn} ${styles.centered}`}>
          <Description appearance="white">
            {dayjs().year()} Portal de Saúde - Todos os direitos reservados
          </Description>
          <Spacing size="xx-small" />
          <Description appearance="white">
            Desenvolvido por{" "}
            <b
              onClick={() => window.open("https://startaideia.com.br/").focus()}
            >
              Startaideia
            </b>
          </Description>
        </div>
      </div>
    </div>
  );
}

export default Footer;
