import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Feedback } from "../../components";

import feedback from "../../assets/sulmed-feedback-concluded-payment.svg";

function PaymentSuccess() {
  const navigate = useNavigate();

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Feedback
        title="Pagamento Realizado!"
        description="O seu pagamento foi realizado com sucesso. Por favor, verifique o comprovante de pagamento clicando no botão abaixo"
        image={feedback}
        buttonText="Visualizar comprovante"
        buttonWidth="230px"
        onClickButton={() => navigate("/")}
        underbuttonText="Voltar para tela inicial"
        underButtonWidth="230px"
        onClickUnderbutton={() => navigate("/")}
      ></Feedback>
    </Layout>
  );
}

export default PaymentSuccess;
