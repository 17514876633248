import React, { useState } from "react";
import styles from "./style.module.scss";
import { maskBirthDate, maskCPF, maskMoney } from "../../../../utils/mask";
import { Column, Input, Spacing } from "../../../../components";

import add from "../../../../assets/icons/icon-add-blue.png";
import edit from "../../../../assets/icons/icon-edit-blue.png";
import checked from "../../../../assets/icons/icon-checkbox-checked.png";
import unchecked from "../../../../assets/icons/icon-checkbox-unchecked.png";

function ExamCard({ id, title, amount, data, onSendForm, onSendFormToAll }) {
  const [formOpened, setFormOpened] = useState(false);
  const [formData, setFormData] = useState({ ...data.form });

  const [checkInputName, setCheckInputName] = useState(false);
  const [checkInputDocument, setCheckInputDocument] = useState(false);
  const [checkInputBirthDate, setCheckInputBirthDate] = useState(false);
  const [checkInputEmail, setCheckInputEmail] = useState(false);
  const [checkInputState, setCheckInputState] = useState(false);
  const [checkInputCity, setCheckInputCity] = useState(false);

  function checkForm() {
    if (formData.name === "" || formData.name.length < 4) {
      setCheckInputName(true);
    } else {
      setCheckInputName(false);
    }

    if (formData.document === "" || formData.document.length < 10) {
      setCheckInputDocument(true);
    } else {
      setCheckInputDocument(false);
    }

    if (formData.birth_date === "" || formData.birth_date.length < 8) {
      console.log(formData.birth_date.length);
      setCheckInputBirthDate(true);
    } else {
      setCheckInputBirthDate(false);
    }

    if (
      formData.email === "" ||
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)
    ) {
      setCheckInputEmail(true);
    } else {
      setCheckInputEmail(false);
    }

    if (/\d/.test(formData.state)) {
      setCheckInputState(true);
    } else {
      setCheckInputState(false);
    }

    if (formData.state === "") {
      setCheckInputState(true);
    } else {
      setCheckInputState(false);
    }

    if (formData.city === "") {
      setCheckInputCity(true);
    } else {
      setCheckInputCity(false);
    }

    if (
      formData.name !== "" &&
      formData.document !== "" &&
      formData.birth_date !== "" &&
      formData.email !== "" &&
      formData.state !== "" &&
      formData.city !== "" &&
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function handleForm() {
    const check = checkForm();
    if (check) {
      if (formData.allPackagesToSameUser) {
        onSendFormToAll(formData);
      } else {
        onSendForm(formData);
      }
      setFormOpened(false);
    }
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.margin}`}>
        <p className={styles.title}>
          <b>{title}</b>
        </p>
        <p>R$ {maskMoney(Number(amount))}</p>
      </div>
      {!formOpened && (
        <>
          {data.form.name === "" ? (
            <div className={`${styles.row} ${styles.end} ${styles.margin}`}>
              <p className={styles.button} onClick={() => setFormOpened(true)}>
                <img src={add} />
                Adicionar Dados do Paciente
              </p>
            </div>
          ) : (
            <div className={`${styles.row} ${styles.margin}`}>
              <p className={styles.title}>{data.form.name}</p>
              <p className={styles.button} onClick={() => setFormOpened(true)}>
                <img src={edit} />
              </p>
            </div>
          )}
        </>
      )}

      {formOpened && (
        <>
          <Spacing size="large" />
          <Column width="100%">
            <div className={styles.row}>
              <Input
                name="name"
                type="text"
                width="100%"
                placeholder="Informe seu nome completo"
                label="Nome"
                value={formData.name}
                error={checkInputName}
                onChange={(event) => {
                  setFormData({ ...formData, name: event.target.value });
                  console.log(formData);
                  if (event.target.value.length >= 3) {
                    setCheckInputName(false);
                  }
                }}
              />
            </div>
            <Spacing size="medium" />
            <div className={`${styles.row} ${styles.start}`}>
              <Column width="44%">
                <Input
                  name="document"
                  type="text"
                  width="100%"
                  placeholder="Somente Números"
                  maxLength={14}
                  label="CPF"
                  value={maskCPF(formData.document)}
                  error={checkInputDocument}
                  onChange={(event) => {
                    setFormData({ ...formData, document: event.target.value });
                    if (event.target.value.length >= 10) {
                      setCheckInputDocument(false);
                    }
                  }}
                />
              </Column>
              <Spacing size="xx-small" />
              <Column width="46%">
                <Input
                  name="birth_date"
                  type="text"
                  width="100%"
                  placeholder="Somente Números"
                  label="Data de Nascimento"
                  maxLength={10}
                  value={maskBirthDate(formData.birth_date)}
                  error={checkInputBirthDate}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      birth_date: event.target.value,
                    });
                    if (event.target.value.length >= 8) {
                      setCheckInputBirthDate(false);
                    }
                  }}
                />
              </Column>
            </div>
            <Spacing size="medium" />
            <div className={styles.row}>
              <Input
                name="email"
                type="text"
                width="100%"
                placeholder="exemplo@gmail.com"
                label="E-mail"
                value={formData.email}
                error={checkInputEmail}
                onChange={(event) => {
                  setFormData({ ...formData, email: event.target.value });
                  if (
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value)
                  ) {
                    setCheckInputEmail(false);
                  }
                }}
              />
            </div>
            <Spacing size="medium" />
            <div className={`${styles.row} ${styles.start}`}>
              <Column width="44%">
                <Input
                  name="state"
                  type="text"
                  width="100%"
                  placeholder="UF"
                  maxLength={2}
                  label="Estado"
                  value={formData.state}
                  error={checkInputState}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      state: event.target.value.toUpperCase(),
                    });
                    if (event.target.value.length === 2) {
                      setCheckInputState(false);
                    }
                  }}
                />
              </Column>
              <Spacing size="xx-small" />
              <Column width="46%">
                <Input
                  name="city"
                  type="text"
                  width="100%"
                  placeholder="Ex: São Paulo"
                  label="Cidade"
                  value={formData.city}
                  error={checkInputCity}
                  onChange={(event) => {
                    setFormData({ ...formData, city: event.target.value });
                    if (event.target.value.length >= 3) {
                      setCheckInputCity(false);
                    }
                  }}
                />
              </Column>
            </div>
            <Spacing size="xx-small" />
            <div
              className={`${styles.row} ${styles.start} ${styles.everyRow}`}
              style={{ margin: "15px 0px" }}
            >
              {formData.allPackagesToSameUser ? (
                <img
                  src={checked}
                  alt="checkbox checked"
                  onClick={() => {
                    setFormData({ ...formData, allPackagesToSameUser: false });
                  }}
                  draggable="false"
                  className={styles.checkbox}
                />
              ) : (
                <img
                  src={unchecked}
                  alt="checkbox unchecked"
                  onClick={() => {
                    setFormData({ ...formData, allPackagesToSameUser: true });
                  }}
                  draggable="false"
                  className={styles.checkbox}
                />
              )}
              <p className={styles.checkboxTitle}>
                Todos os pacotes serão para o mesmo paciente
              </p>
            </div>
            <div
              className={`${styles.row} ${styles.end}`}
              style={{ marginBottom: "15px" }}
            >
              <p className={styles.button} onClick={() => handleForm()}>
                Concluir
              </p>
            </div>
          </Column>
        </>
      )}
    </div>
  );
}

export default ExamCard;
