import React from "react";
import styles from "./styles.module.scss";

function Text({
  children,
  maxWidth = "500px",
  bolder = false,
  pointer = false,
  appearance = "default",
  padding,
  position,
  onClick,
}: any) {
  return (
    <p
      className={`${styles.text} 
      ${position === "start" && styles.start} 
      ${position === "centered" && styles.centered} 
      ${position === "end" && styles.end} 
      ${appearance === "default" && styles.default} 
      ${appearance === "primary" && styles.primary} 
      ${appearance === "complementary" && styles.complementary}
      ${appearance === "disabled" && styles.disabled} 
      ${appearance === "success" && styles.success} 
      ${appearance === "warning" && styles.warning} 
      ${appearance === "error" && styles.error} 
      ${appearance === "grey" && styles.grey} 
      ${appearance === "white" && styles.white}
      `}
      style={{
        maxWidth: maxWidth && maxWidth,
        fontWeight: bolder ? "bolder" : "normal",
        cursor: pointer ? "pointer" : "default",
        padding: padding && padding,
      }}
      onClick={onClick}
    >
      {children}
    </p>
  );
}

export default Text;
