import React from "react";
import styles from "./styles.module.scss";
import Spacing from "../Spacing";
import Button from "../Button";

function Feedback({
  image,
  title,
  description,
  buttonText,
  buttonWidth = "140px",
  onClickButton,
  underbuttonText,
  underButtonWidth = "140px",
  onClickUnderbutton,
}: any) {
  return (
    <div className={styles.container}>
      <img src={image} alt="" />
      <Spacing size="xx-large" />
      <h1>{title}</h1>
      <Spacing size="xxx-small" />
      <p>{description}</p>
      <Spacing size="x-large" />
      {buttonText && (
        <Button
          text={buttonText}
          width={buttonWidth}
          appearance="primary"
          onClick={onClickButton}
        />
      )}
      <Spacing size="medium" />
      {underbuttonText && (
        <Button
          appearance="ghost"
          width={underButtonWidth}
          text={underbuttonText}
          onClick={onClickUnderbutton}
        />
      )}
    </div>
  );
}

export default Feedback;
