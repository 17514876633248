import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Landing Page | User`s Journey
import Home from "../views/Home";
import Detail from "../views/Detail";
import Product from "../views/Product";
import Registration from "../views/Registration";
import Auth from "../views/Auth";
import Summary from "../views/Summary";
import PaymentBeneficiaries from "../views/PaymentBeneficiaries";
import Payment from "../views/Payment";
import PaymentSuccess from "../views/PaymentSuccess";
import PaymentConcluded from "../views/PaymentConcluded";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={`/produto/:id`} element={<Product />} />
        <Route path={`/detalhe/:id`} element={<Detail />} />
        <Route path="/resumo" element={<Summary />} />
        <Route path="/cadastro" element={<Registration />} />
        <Route path="/autenticacao" element={<Auth />} />
        <Route path="/pagamento/beneficiarios" element={<PaymentBeneficiaries />} />
        <Route path="/pagamento" element={<Payment />} />
        <Route path="/pagamento/sucesso" element={<PaymentSuccess />} />
        <Route path="/pagamento/concluido" element={<PaymentConcluded />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
