import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import ReactCodeInput from "react-code-input";
import { Layout, Spacing, Row, Button } from "../../components";
import Alerts from "../../components/Alert";

import arrow from "../../assets/icons/icon-arrow-right-white.png";

function Auth() {
  const navigate = useNavigate();
  const [code, setCode] = useState<any>("");
  const [alertError, setAlertError] = useState<boolean>(false);
  const [alertCode, setAlertCode] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { context }: any = useContext(DataContext);

  function handleCode(event: any) {
    setCode(event);
  }

  function handleAuthenticate() {
    if (!loading) {
      setLoading(true);
      const formData = new FormData();
      formData.append("token", code);
      formData.append("purchase_id", context.info.purchase_id);

      axios
        .post(
          `${process.env.REACT_APP_LINK_API}/packages/purchase/verifytoken`,
          formData
        )
        .then((response) => {
          if (response.data.success) {
            setTimeout(() => {
              navigate("/pagamento/beneficiarios");
              setLoading(false);
            }, 4000);
          }
          if (!response.data.success) {
            setAlertError(true);
            setLoading(false);

            setTimeout(() => {
              setAlertError(false);
            }, 3000);
          }
        });
    }
  }

  function resendCode() {
    const formData = new FormData();
    formData.append("purchase_id", context.info.purchase_id);

    axios
      .post(
        `${process.env.REACT_APP_LINK_API}/packages/purchase/generatetoken`,
        formData
      )
      .then((response) => {
        setAlertCode(true);
        setTimeout(() => {
          setAlertCode(false);
        }, 3000);
      });
  }
  
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
  }, []);

  return (
    <Layout navbar={true} verticalCentered={true}>
      {alertError && (
        <Alerts
          type="error"
          title="Atenção!"
          text="O campo de token não foi preenchido ou esta incorreto!"
        />
      )}
      {alertCode && (
        <Alerts
          type="success"
          title="Token Enviado!"
          text="Um novo token foi enviado para você!"
        />
      )}
      <div className={styles.header}>
        <h1>Informe o código de autenticação</h1>
        <Spacing size="x-small" />
        <p>
          Para garantir a sua segurança, enviamos um código de autenticação via
          SMS e E-mail. Por favor, verifique sua caixa de entrada, incluindo a
          pasta de spam
        </p>
      </div>
      <Spacing size="xxx-large" />
      <ReactCodeInput
        type="number"
        fields={6}
        value={code}
        name={code}
        autoFocus={true}
        inputMode={"latin"}
        onChange={(event: any) => {
          handleCode(event);
        }}
        inputStyle={{
          fontFamily: "monospace",
          borderRadius: "20px",
          border: "1px solid #E8ECEE",
          margin: "4px 14px",
          width: "80px",
          height: "100px",
          fontSize: "62px",
          boxSizing: "border-box",
          color: "black",
          backgroundColor: "#FFFFFF",
          borderColor: "#E8ECEE",
          userSelect: "none",
          outline: "none",
          textAlign: "center",
        }}
      />
      <Spacing size="large" />
      <div className={styles.codeActions}>
        <a href="#" onClick={resendCode} className={styles.link}>
          Não recebi o código
        </a>
      </div>

      <Spacing size="xxx-large" />

      <Row width="70%" position="end">
        <div className={styles.cardFooter}>
          <Button
            text="Enviar"
            loading={loading}
            width="150px"
            appearance={loading ? "disabled" : "primary"}
            onClick={handleAuthenticate}
          >
            <img src={arrow} alt="" />
          </Button>
          <Spacing size="xx-small" />
        </div>
      </Row>
    </Layout>
  );
}

export default Auth;
