import React from "react";
import styles from "./styles.module.scss";

function Row({ width = "100%", position = "centered", children }: any) {
  return (
    <div
      className={`
      ${styles.container} 
      ${position === "start" && styles.start} 
      ${position === "centered" && styles.centered} 
      ${position === "end" && styles.end} 
      `}
      style={{
        width: width && width,
      }}
    >
      {children}
    </div>
  );
}

export default Row;
