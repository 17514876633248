import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Column,
  Description,
  Input,
  Modal,
  Row,
  Text,
  Spacing,
  Button,
} from "../../components";
import Product from "../../views/Summary/components/Product";
import { maskCep, maskReset } from "../../utils/mask";
import { DataContext } from "../../context/DataContext";
import { FaChevronDown, FaMapMarkerAlt } from "react-icons/fa";

import cart from "../../assets/icons/icon-cart-navbar.png";
import error from "../../assets/icons/error-unenabled-location-icon.png";

function SearchField({ city, onClick }: any) {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  // eslint-disable-next-line no-unneeded-ternary
  const [userCity, setUserCity] = useState(city ? city : "Porto Alegre");
  const [modalCart, setModalCart] = useState(false);
  const [modalLocation, setModalLocation] = useState(false);
  const [modalInitialLocation, setModalInitialLocation] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [checkEInputCep, setCheckInputCep] = useState(false);
  const [errorCEP, setErrorCEP] = useState(false);
  const [cep, setCep] = useState("");
  const {
    context,
    handleData,
    updatePackages,
    updateLocation,
    updateProducts,
    deleteProduct,
  }: any = useContext(DataContext);

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("@data"));
    if (storage === null || storage.location.postCode === "") {
      setModalInitialLocation(true);
    } else {
      setUserCity(storage.location.city);
    }

    checkProductsValue();
  }, []);

  function addProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount += 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });
  }

  function subsProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount -= 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });
  }

  function checkProductsValue() {
    context.products.map((product) => {
      axios
        .get(`${process.env.REACT_APP_LINK_API}/packages-id/${product.id}`)
        .then((resp) => {
          // eslint-disable-next-line no-param-reassign
          product.value = resp.data.packages[0].value;
        })
        .finally(() => {
          handleData(context);
        });
    });
  }

  function searchUserInitialLocation() {
    axios
      .get(
        `${process.env.REACT_APP_LINK_API}/packages-by-regions/${maskReset(
          cep
        )}`
      )
      .then((response) => {
        if (response.data.packages.length === 0) {
          setModalErrorIsOpen(true);
        } else {
          getUserCity();
          updatePackages(response.data.packages);
          setModalInitialLocation(false);
          setCep("");
        }
      });
  }

  function searchUserLocation() {
    axios
      .get(
        `${process.env.REACT_APP_LINK_API}/packages-by-regions/${maskReset(
          cep
        )}`
      )
      .then((response) => {
        if (response.data.packages.length === 0) {
          setModalErrorIsOpen(true);
        } else {
          getUserCity();
          updatePackages(response.data.packages);
          setModalLocation(false);
          setCep("");
        }
      });
  }

  function getUserCity() {
    axios
      .get(`https://viacep.com.br/ws/${maskReset(cep)}/json/`)
      .then((response) => {
        setUserCity(response.data.localidade);
        updateLocation({
          postCode: maskReset(response.data.cep),
          city: response.data.localidade,
        });
      })
      .catch(() => {
        setErrorCEP(true);
        document
          .getElementById("cepInput")
          .classList.add("styles_shaking__YtHrE");
        setCheckInputCep(true);
        setTimeout(() => {
          document
            .getElementById("cepInput")
            .classList.remove("styles_shaking__YtHrE");
        }, 3000);
      });
  }

  return (
    <>
      {/* MODAL CART */}
      <Modal
        isOpen={modalCart}
        title="Carrinho de compras"
        description=""
        width="800px"
        footerButtonFirst="Voltar"
        footerButtonFirstAppearance="ghost"
        footerButtonFirstWidth="140px"
        onClickFooterFirst={() => setModalCart(false)}
        footerButtonSecond="Finalizar Compra"
        footerButtonSecondAppearance={
          context.products.length > 0 ? "primary" : "disabled"
        }
        footerButtonSecondWidth="140px"
        onClickFooterSecond={() =>
          context.products.length > 0 ? navigate("/cadastro") : ""
        }
        onClickClose={() => setModalCart(false)}
      >
        <div className={styles.modalContent}>
          <Spacing size="large" />
          {context.products.length > 0 ? (
            <>
              <Spacing size="x-large" />
              {context.products.map((product) => (
                <Product
                  image={`https://conexx.s3.amazonaws.com/${product.image}`}
                  title={product.title}
                  description={product.description}
                  productValue={product.value}
                  totalValue={product.totalValue}
                  amount={product.amount}
                  onClickDelete={() => deleteProduct(product.id)}
                  addProduct={(event) => addProduct(product.id, event)}
                  subsProduct={(event) => subsProduct(product.id, event)}
                />
              ))}
              <Spacing size="xxx-large" />
            </>
          ) : (
            <Row>
              <Description appearance="grey">
                Nenhum produto foi adicionado ao carrinho
              </Description>
            </Row>
          )}
        </div>
      </Modal>

      {/* MODAL SEARCH CEP INITIAL */}
      <Modal
        isOpen={modalInitialLocation}
        title="Informe o CEP da sua região"
        description="Iremos filtrar os serviços de saúde associados a
        Conexxa disponíveis na sua região."
        footerPosition="center"
        footerButtonSecond="Continuar"
        footerButtonSecondAppearance="primary"
        footerButtonSecondWidth="74px"
        onClickFooterSecond={() => {
          if (cep.length < 10) {
            document
              .getElementById("cepInput")
              .classList.add("styles_shaking__YtHrE");
            setCheckInputCep(true);
            setTimeout(() => {
              document
                .getElementById("cepInput")
                .classList.remove("styles_shaking__YtHrE");
            }, 3000);
          } else {
            searchUserInitialLocation();
          }
        }}
      >
        <Spacing size="large" />
        <div id="cepInput" style={{ overflow: "hidden" }}>
          <Input
            name="cep"
            type="text"
            width="100%"
            maxLength="10"
            placeholder="CEP"
            error={checkEInputCep}
            value={maskCep(cep)}
            onChange={(event) => {
              setErrorCEP(false);
              if (
                !isNaN(event.target.value) &&
                event.target.value.length <= 9
              ) {
                setCheckInputCep(false);
                setCep(maskCep(event.target.value));
              } else {
                setCep(maskCep(event.target.value));
              }
            }}
          />
          {errorCEP && (
            <p style={{ fontSize: "11px", color: "red" }}>
              <i>O CEP informado não foi encontrado</i>
            </p>
          )}
        </div>
        <Spacing size="small" />
        <a
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="__blank"
          style={{
            fontSize: "12px",
            color: "#78909C",
            textDecoration: "none",
            cursor: "pointer",
            marginLeft: "5px",
          }}
        >
          Não sabe seu CEP?
        </a>
        <Spacing size="large" />
      </Modal>

      {/* MODAL SEARCH CEP */}
      <Modal
        isOpen={modalLocation}
        title="Informe o CEP da sua região"
        description="Iremos filtrar os serviços de saúde associados a
  Conexxa disponíveis na sua região."
        footerButtonFirst="Cancelar"
        footerButtonFirstAppearance="ghost"
        footerButtonFirstWidth="74px"
        onClickFooterFirst={() => setModalLocation(false)}
        footerButtonSecond="Continuar"
        footerButtonSecondAppearance="primary"
        footerButtonSecondWidth="74px"
        onClickFooterSecond={() => {
          if (cep.length < 10) {
            document
              .getElementById("cepInput")
              .classList.add("styles_shaking__YtHrE");
            setCheckInputCep(true);
            setTimeout(() => {
              document
                .getElementById("cepInput")
                .classList.remove("styles_shaking__YtHrE");
            }, 3000);
          } else {
            searchUserLocation();
          }
        }}
        onClickClose={() => setModalLocation(false)}
      >
        <Spacing size="large" />
        <div id="cepInput">
          <Input
            name="cep"
            type="text"
            width="100%"
            maxLength="10"
            placeholder="CEP"
            error={checkEInputCep}
            value={maskCep(cep)}
            onChange={(event) => {
              if (!isNaN(event.target.value)) {
                setCheckInputCep(false);
                setCep(maskCep(event.target.value));
              }
            }}
          />
          {errorCEP && (
            <p style={{ fontSize: "11px", color: "red" }}>
              <i>O CEP informado não foi encontrado</i>
            </p>
          )}
        </div>
        <Spacing size="small" />
        <a
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="__blank"
          style={{
            fontSize: "12px",
            color: "#78909C",
            textDecoration: "none",
            cursor: "pointer",
            marginLeft: "5px",
          }}
        >
          Não sabe seu CEP?
        </a>
        <Spacing size="large" />
      </Modal>

      {/* MODAL PACKAGES NOT FOUND */}
      <Modal
        isOpen={modalErrorIsOpen}
        image={error}
        imageWidth="70px"
        title="Serviço indisponível em sua região"
        description="Este pacote não está disponível para sua região. Mude a sua localização ou selecione um dos serviços disponíveis da sua região"
        onClickClose={() => setModalErrorIsOpen(false)}
      >
        <Spacing size="xxx-large" />
        <Row>
          <Column position="centered">
            <Button
              text="Ver serviços disponíveis"
              width="195px"
              onClick={() => setModalErrorIsOpen(false)}
            />
            <Spacing size="medium" />
            <Button
              text="Mudar localização"
              appearance="ghost"
              width="165px"
              onClick={() => {
                setModalErrorIsOpen(false);
                setModalLocation(true);
              }}
              iconPosition="left"
            >
              <FaMapMarkerAlt />
            </Button>
          </Column>
        </Row>
      </Modal>

      <div className={styles.searchField}>
        <Column>
          <div className={styles.location}>
            <Text
              appearance="default"
              pointer={true}
              onClick={() => setModalLocation(true)}
              position="centered"
            >
              {userCity}
              <Spacing size="xxx-small" />
              <FaChevronDown />
            </Text>
          </div>
        </Column>
        <Column position="center">
          {/* <Input
            name="search"
            type="text"
            width="100%"
            icon={<FaSearch />}
            placeholder="O que você precisa hoje?"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          /> */}
        </Column>
        <div className={styles.icons}>
          <Row>
            <div className={styles.cart} onClick={() => navigate("/resumo")}>
              <img src={cart} className={styles.icon} />
              <div className={styles.notification}>
                {context.products.length}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SearchField;
