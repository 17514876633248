import React from "react";
import styles from "./styles.module.scss";
import { buttonProps } from "../../types";

function Button({
  size = "md",
  width = "300px",
  appearance = "primary",
  text = "I`m a button!",
  children,
  iconPosition = "right",
  loading = false,
  onClick,
}: buttonProps) {
  return (
    <div
      className={`
      ${styles.container} 
      ${size === "sm" && styles.sm} 
      ${size === "md" && styles.md} 
      ${size === "lg" && styles.lg} 
      ${appearance === "primary" && styles.primary}
      ${appearance === "complementary" && styles.complementary}
      ${appearance === "ghost" && styles.ghost}
      ${appearance === "disabled" && styles.disabled}
      ${appearance === "success" && styles.success}
      ${appearance === "ghost-success" && styles.ghostSuccess}
      ${appearance === "warning" && styles.warning}
      ${appearance === "ghost-warning" && styles.ghostWarning}
      ${appearance === "error" && styles.error}
      ${appearance === "ghost-error" && styles.ghostError}
      `}
      style={{
        width: width && width,
      }}
      onClick={onClick}
    >
      {iconPosition === "left" ? children : ""}
      <div className={styles.content}>
        {loading && (
          <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle
              className={styles.path}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        )}
        <p>{text}</p>
      </div>
      {iconPosition === "right" ? children : ""}
    </div>
  );
}

export default Button;
