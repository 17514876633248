import React, { useEffect } from "react";
import "./App.scss";
import AppRoutes from "./routes/index";
import alert from "./assets/icons/excplamation-icon.png";

function App() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className="App">
      <div className="alert">
        <h4>
          {" "}
          <img src={alert} /> Atenção!
        </h4>
        <p>
          Este é um ambiente de Homologação. Portanto os produtos e valores são
          fictícios, para fins de teste da plataforma (vendas e entrega do
          produto não serão efetuadas no atual momento).
        </p>
      </div>
      <AppRoutes />
    </div>
  );
}

export default App;
